module.exports.linkResolver = (doc) => {
  if (doc.type === "cover_page") return `/cover/`;
  if (doc.type === "careers_page") return `/careers/`;
  if (doc.type === "faq_page") return `/faq/`;
  if (doc.type === "contact_legal") return `/additional-disclosures/`;
  if (doc.type === "contact_us") return `/contact/`;
  if (doc.type === "404_page") return `/404/`;
  if (doc.type === "blog_page") return `/blog/`;
  if (doc.type === "privacy_policy") return `/privacy-policy/`;
  if (doc.type === "terms_of_use") return `/terms-of-use/`;
  if (doc.type === "blog") return `/blog/${doc.uid}/`;
  if (doc.internal.type === "Position") return `/careers/${doc.uid}/`;
  return "/";
};
