import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import Stack, { StackProps } from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const AppLinksContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.app-links-container {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
    & > h4 {
      color: ${({ theme }) => theme.palette.secondary.main};
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 100%;
    }
    & > .app-links {
      display: flex;
      gap: ${({ theme }) => theme.spacing(1.5)};
      & > a {
        padding: 0;
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      gap: ${({ theme }) => theme.spacing(2)};
      & > h4 {
        font-size: 13px;
        max-width: none;
      }
      & > .app-links {
        gap: ${({ theme }) => theme.spacing(1)};
      }
    }
  }
`;

export const CopyrightContainer = styled(
  (props: WithThemeProps & TypographyProps) => <Typography {...props} />
)`
  color: ${({ theme: { palette } }: ThemeType) => palette.primaryLight};
  opacity: 0.8;
  font-size: 13px;
  ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
    font-size: 10px;
  }
`;

export const LinksGroupContainer = styled(
  (props: WithThemeProps & StackProps) => <Stack {...props} />
)`
  &.links-group-container {
    gap: 12px;

    p {
      font-size: 13px;
      font-weight: 700;
      color: ${({ theme: { palette } }: ThemeType) => palette.primaryLight};
    }
    & > a,
    & > .newsletter-signup,
    & > .MuiLink-root {
      text-decoration: none;
      font-size: 13px;
      line-height: 100%;
      color: ${({ theme: { palette } }: ThemeType) => palette.primaryLight};
    }
    & > .newsletter-signup {
      padding: 0;
      margin: 0;
      text-transform: none;
      align-items: flex-start;
      min-width: 0;
      justify-content: flex-start;
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing(1)};
    }
  }
`;
