import React, { useRef, useEffect } from "react";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { NewsletterModalContainer } from "./styles";
import { NewsletterModalProps } from "./types";
import CloseIcon from "@mui/icons-material/Close";
import Newsletter from "components/pages/blog/components/top/components/newsletter";
import FacebookLogo from "assets/svg/facebook_logo.svg";
import TwitterLogo from "assets/svg/twitter_logo.svg";
import LinkedInLogo from "assets/svg/linkedin_logo.svg";
import InstagramLogo from "assets/svg/instagram_logo.svg";

const NewsletterModal: React.FC<NewsletterModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const modalRef = useRef<HTMLDivElement>();
  return (
    <NewsletterModalContainer
      onClose={closeModal}
      open={isOpen}
      className="newsletter-container"
    >
      <Box ref={modalRef} className="modal-content">
        <IconButton onClick={closeModal} className="modal-close">
          <CloseIcon />
        </IconButton>
        <Typography variant="h3">Unpack good content</Typography>
        <Typography>
          Stay in the know when new product updates, announcements, travel inspo
          and more land here.
        </Typography>
        <Newsletter location="footer" />
        <Box className="social-links">
          <LinkedInLogo />
          <a
            href="https://www.instagram.com/faye.travel/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramLogo />
          </a>
          <FacebookLogo />
          <a
            href="https://twitter.com/FayeTravel"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterLogo />
          </a>
        </Box>
      </Box>
    </NewsletterModalContainer>
  );
};

export default NewsletterModal;
