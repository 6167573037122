import * as React from "react";
import { Button, CircularProgress } from "@mui/material";
import { LoaderButtonProps } from "../../types";
import CheckIcon from "@mui/icons-material/Check";
const LoaderButton: React.FC<LoaderButtonProps> = ({
  loading,
  done,
  onClick,
}) => {
  return loading ? (
    <Button variant="contained" className="signup-button" disabled={true}>
      <CircularProgress size={20} />
    </Button>
  ) : done ? (
    <Button variant="contained" className="signup-button">
      <CheckIcon />
    </Button>
  ) : (
    <Button variant="contained" className="signup-button" onClick={onClick}>
      Sign up
    </Button>
  );
};

export default LoaderButton;
