import React, { useState, useCallback } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { LogoContainer } from "./styles";
import { LogoProps } from "./types";
import { Tooltip, Box } from "@mui/material";
import packageJson from "../../../../package.json";

const Logo: React.FC<LogoProps> = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen((tooltipOpen) => !tooltipOpen);
  };

  const handleVersionAlertTriggerClick = useCallback(
    (event: React.MouseEvent) => {
      if (event.detail === 3) {
        toggleTooltip();
      }
    },
    [toggleTooltip]
  );
  const data = useStaticQuery(graphql`
    query {
      logo: allPrismicLogo {
        nodes {
          data {
            logo {
              gatsbyImageData(width: 90)
            }
          }
        }
      }
    }
  `);
  return (
    <LogoContainer className="logo-container">
      <GatsbyImage
        alt={"homepage link"}
        image={data.logo.nodes[0].data.logo.gatsbyImageData}
      />
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => setTooltipOpen(false)}
        open={tooltipOpen}
        title={packageJson.version}
        className="version-alert-trigger-tooltip"
      >
        <Box
          className="version-alert-trigger"
          onClick={handleVersionAlertTriggerClick}
        />
      </Tooltip>
    </LogoContainer>
  );
};

export default Logo;
