import React from "react";
import { useTheme } from "@mui/system";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useMediaQuery } from "@mui/material";
import Copyright from "./components/copyright";
import LinksGroup from "./components/links-group";
import AppLinks from "./components/app-links";
import SocialLogos from "./components/social-logos";
import { FooterContainer } from "./styles";
import { LinkGroup, FooterProps } from "./types";
import Link from "../link";
import { Box } from "@mui/material";
import { PrismicRichText } from "@prismicio/react";

const Footer: React.FC<FooterProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      footer: allPrismicFooter {
        nodes {
          data {
            faye_logo {
              gatsbyImageData
            }
            googleplay {
              gatsbyImageData(width: 138)
            }
            appstore {
              gatsbyImageData(width: 138)
            }
            disclaimer {
              richText
            }
            body {
              ... on PrismicFooterDataBodyLinkGroup {
                id
                primary {
                  group_name {
                    text
                  }
                  active
                }
                items {
                  active
                  link_name {
                    text
                  }
                  link_path {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const document = data.footer.nodes[0].data;

  return (
    <FooterContainer className="footer-container">
      <Box className="footer">
        <Box className="footer-top">
          <Box className="section-company">
            <GatsbyImage
              className={"faye-logo-footer"}
              alt={"faye logo"}
              image={document.faye_logo.gatsbyImageData}
            />
            {!mobile && <SocialLogos />}
          </Box>
          <AppLinks
            googlePlaySVG={document.googleplay.gatsbyImageData}
            appleStoreSVG={document.appstore.gatsbyImageData}
          />

          <Box className="link-groups-container">
            {document.body.map((linkGroup: LinkGroup) => {
              return (
                <LinksGroup
                  key={linkGroup.id}
                  first={{
                    title: linkGroup.primary.group_name.text,
                  }}
                  list={linkGroup.items.map((item) => {
                    return item.active
                      ? {
                          title: item.link_name.text,
                          path: item.link_path.text,
                        }
                      : null;
                  })}
                />
              );
            })}
            {mobile && <SocialLogos />}
          </Box>
        </Box>

        <Box className="footer-bottom">
          <Copyright />
          <Box className="link-groups-container bottom-container">
            <Link to="/terms-of-use">Terms of use</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </Box>
        </Box>
        <PrismicRichText field={document.disclaimer.richText} />
      </Box>
    </FooterContainer>
  );
};

export default Footer;
