import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Layout from "components/common/layout";
import BlogTemplate from "templates/blog-template";
import { linkResolver } from "utils/linkResolver";
import theme from "utils/theme";
import { PrismicProvider } from '@prismicio/react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import "styles/font-twk-lausanne.css";
import "styles/font-utopia.css";
import "styles/global.css";
import { Link } from "gatsby";

export const wrapRootElement = ({ element }) => {
  return (
    <PrismicProvider
    linkResolver={linkResolver}
       internalLinkComponent={({ href, ...props }) => (
      <Link className="internal-link" to={href} {...props} />
    )}
   >
     <ThemeProvider theme={theme}>
      <CssBaseline /> 
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            linkResolver,
            componentResolver: componentResolverFromMap({ blog: BlogTemplate }),
          },
        ]}
      >
       {element}
      </PrismicPreviewProvider>
     </ThemeProvider>
    </PrismicProvider>

  );
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
};
