import React from "react";
import Link from "../link";
import { Box, ListItem } from "@mui/material";
import Logo from "../logo";
import useScrollPosition from "hooks/useScrollPosition";
import { NavBarContainer } from "./styles";
import GetCoveredButton from "../cover-button";
import { useLocation } from "@reach/router";
import classNames from "classnames";

const NavBar: React.FC = () => {
  const { offset, scrollUp, screenHeightScrolled } = useScrollPosition();
  const location = useLocation();
  const isSingleBlogPage =
    location.pathname.indexOf(`/blog/`) !== -1 && location.pathname.length >= 7;
  return (
    <NavBarContainer
      className={classNames(
        "nav-bar-container",
        screenHeightScrolled && "sticky-nav",
        isSingleBlogPage && "white-background"
      )}
    >
      <Box className="nav-bar-max-width">
        <Box className={"nav-logo-container"}>
          <Link to={"/"}>
            <Logo />
          </Link>
        </Box>
        <Box component="ul" className="links-container">
          <ListItem>
            <Link className={"what-we-cover-link"} color="primary" to="/cover">
              What's covered
            </Link>
          </ListItem>
          <ListItem className="get-covered-button-container">
            <GetCoveredButton
              location="upper_bar_cta"
              className="get-covered-button"
              text="Get covered"
            />
          </ListItem>
        </Box>
      </Box>
    </NavBarContainer>
  );
};

export default NavBar;
