import * as React from "react";
import { Box, Typography } from "@mui/material";
import FacebookLogo from "assets/svg/facebook_logo.svg";
import TwitterLogo from "assets/svg/twitter_logo.svg";
import LinkedInLogo from "assets/svg/linkedin_logo.svg";
import InstagramLogo from "assets/svg/instagram_logo.svg";

const SocialLogos: React.FC = () => {
  return (
    <Box className="logos-and-follow">
      <Typography>Follow us</Typography>
      <Box className="social-logos">
        <a
          href="https://www.facebook.com/FayeTravelInsurance"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookLogo fill="white" />
        </a>
        <a href="https://twitter.com/FayeTravel" target="_blank">
          <TwitterLogo fill="white" />
        </a>
        <a
          href="https://www.linkedin.com/company/fayetravelinsurance/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInLogo fill="white" />
        </a>
        <a href="https://www.instagram.com/faye.travel/" target="_blank">
          <InstagramLogo fill="white" />
        </a>
      </Box>
    </Box>
  );
};

export default SocialLogos;
