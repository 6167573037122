import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import * as React from "react";
import MuiLink from "@mui/material/Link";

export type CustomGatsbyLinkProps = Omit<GatsbyLinkProps<{}>, "ref">;

const Link: React.FC<CustomGatsbyLinkProps> = ({
  className,
  children,
  ...props
}) => (
  <MuiLink
    component={GatsbyLink}
    className={className}
    underline="none"
    {...props}
  >
    {children}
  </MuiLink>
);

export default Link;
