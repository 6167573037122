import React from "react";
import { Box } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import Arrow from "assets/svg/arrow.svg";
import MoreBlogs from "../more-blogs";
import { BlogContentContainer, AvatarStyled } from "../styles";
import { BlogContentProps } from "../types";
import Link from "components/common/link";
import { PrismicRichText } from "@prismicio/react";

const BlogContent: React.FC<BlogContentProps> = ({
  author,
  date,
  title,
  image,
  imageCaption,
  firstParagraph,
  content,
  uid,
}) => {
  return (
    <BlogContentContainer className="blog-content-container">
      <Link className="back-to-blogs-link" to="/blog">
        <Box className="back-to-blogs-container">
          <Arrow />
          <Box className="back-to-blogs-text">
            <p>Back</p>
          </Box>
        </Box>
      </Link>
      <Box className="blog-content-inner">
        <Box className="blog-header-container">
          <Box className="blog-title-container">
            <PrismicRichText field={title} />
          </Box>
          <Box className="author-container">
            <Box className="author-and-date">
              <AvatarStyled>
                <GatsbyImage
                  image={author.author_image.gatsbyImageData}
                  alt="author image"
                />
              </AvatarStyled>
              <Box className="author-name">
                <PrismicRichText field={author.author_name.richText} />
              </Box>
              <Box className="blog-date">{date}</Box>
            </Box>
          </Box>
        </Box>
        <Box className="main-content-container">
          <PrismicRichText field={firstParagraph} />

          {content
            .filter((item) => !item.primary.is_final_paragraph)
            .map((paragraph, index) => {
              return (
                <Box key={index} className="content-paragraph">
                  <PrismicRichText
                    field={paragraph.primary.section_title.richText}
                  />
                  <PrismicRichText
                    field={paragraph.primary.section_paragraph.richText}
                  />
                </Box>
              );
            })}
          <Box className="image-container">
            <GatsbyImage className="page-inner-image" image={image} alt="" />
            <Box className="caption-container">
              <PrismicRichText field={imageCaption} />
            </Box>
          </Box>
          {content
            .filter((item) => item.primary.is_final_paragraph)
            .map((paragraph, index) => {
              return (
                <Box key={index} className="content-paragraph">
                  <PrismicRichText
                    field={paragraph.primary.section_title.richText}
                  />
                  <PrismicRichText
                    field={paragraph.primary.section_paragraph.richText}
                  />
                </Box>
              );
            })}
        </Box>
        <MoreBlogs uid={uid} />
      </Box>
    </BlogContentContainer>
  );
};

export default BlogContent;
