import React from "react";
import NavBar from "../nav";
import Footer from "../footer";
import GetCoveredButton from "../cover-button";
import { Box } from "@mui/material";
import { LayoutContainer } from "./styles";

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  const showGetCoveredButton =
    location.pathname.indexOf("/careers/") === -1 &&
    location.pathname.indexOf("/blog/") === -1;

  return (
    <LayoutContainer className="layout-container">
      <NavBar />
      <Box component="main" className="main-content-container">
        {children}
        {showGetCoveredButton && (
          <Box className="bottom-nav-container">
            <GetCoveredButton
              location="upper_bar_cta"
              className="get-covered-button-mobile-layout"
              text="Get covered"
            />
          </Box>
        )}
      </Box>
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;
