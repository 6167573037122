import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/system";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const LayoutContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="div" {...props} />
))`
  &.layout-container {
    min-width: 100vw;
    padding: 0;
    & > .main-content-container {
      position: relative;
      width: 100%;
      & > .bottom-nav-container {
        position: sticky;
        display: none;
        bottom: 0;
        background-color: ${({ theme: { palette } }: ThemeType) =>
          palette.p_pink.background};
        z-index: 90;
        width: 100%;
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
        & > .get-covered-button-mobile-layout {
          width: 100%;
          height: 48px;
          text-transform: none;
          font-size: 18px;
          font-weight: normal;
          box-shadow: none;
        }
      }
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        & > .bottom-nav-container {
          display: block;
        }
      }
    }
  }
`;
