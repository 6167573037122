import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const BlogContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="div" {...props} />
))`
  &.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.p_white.full};
  }
`;
