import React from "react";
import { styled } from "@mui/system";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const NavBarContainer = styled((props: WithThemeProps & any) => (
  <nav {...props} />
))`
  &.nav-bar-container {
    position: fixed;
    min-width: 100vw;
    z-index: 100;
    transition: background-color 250ms ease-in, padding 250ms ease-in;

    &.sticky-nav,
    &.white-background {
      position: fixed;
      background-color: ${({ theme: { palette } }: ThemeType) =>
        palette.p_white.full};

      & > .nav-bar-max-width {
        transition: background-color 250ms ease-in, padding 250ms ease-in;
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(2, 10)};
        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          padding: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
        }
        & > .links-container {
          & > * {
            & > .get-covered-button {
              outline: ${({ theme: { palette } }: ThemeType) =>
                `1px solid ${palette.primary.main}`};
            }
          }
        }
      }
    }
    & > .nav-bar-max-width {
      display: flex;
      justify-content: space-between;
      max-width: 1440px;
      margin: 0 auto;
      align-items: center;
      transition: background-color 250ms ease-in, padding 250ms ease-in;
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(4, 10, 2, 10)};
      & > .links-container {
        display: flex;
        margin: 0;
        padding: 0;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(10)};
        & > * {
          padding: 0;
          font-size: 14px;
          font-weight: 600;
          & > .get-covered-button {
            text-transform: none;
            background-color: ${({ theme: { palette } }: ThemeType) =>
              palette.p_white.full};
            color: ${({ theme: { palette } }: ThemeType) =>
              palette.primary.main};
            width: 200px;
            height: 40px;
            padding: 0;
            font-size: 14px;
            font-weight: bold;
            box-shadow: none;
          }
        }
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      & > .nav-bar-max-width {
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
        & > .links-container {
          & > .get-covered-button-container {
            display: none;
          }
          & > * {
            & > .what-we-cover-link {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
`;
