import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const NewsletterContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="div" {...props} />
)`
  &.newsletter-container {
    display: flex;
    align-items: center;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
    & > .email-input {
      height: 52px;
      width: 416px;
      background-color: ${({ theme: { palette } }: ThemeType) =>
        palette.p_white.full};
      & > fieldset {
        border: 1px solid #bfbfbf;
      }
      &.Mui-error {
        & > fieldset {
          border: 1px solid red;
        }
      }
      & > input {
        padding-left: 5px;
        &::placeholder {
          color: ${({ theme: { palette } }: ThemeType) =>
            palette.p_grey.border};
        }
      }
    }
    & > .signup-button {
      height: 52px;
      box-shadow: none;
      text-transform: none;
      width: 146px;
      font-size: 18px;
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      width: 100%;
      & > .email-input {
        width: auto;
        flex-basis: 70%;
        font-size: 12px;
      }
      & > .signup-button {
        width: auto;
        flex-basis: 30%;
        font-size: 16px;
        padding: 0;
      }
    }
  }
`;

export const BlogTypesContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="div" {...props} />
))`
  &.blog-types-container {
    display: flex;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
    & > .single-blog-type {
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      min-width: 0;
      border-radius: 0;
      font-family: "utopia-std-display";
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 1.5)};
      color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
      text-transform: none;
      &.active-type,
      &:hover {
        cursor: pointer;
        color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};
        background-color: ${({ theme: { palette } }: ThemeType) =>
          palette.p_pink.background};
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
      width: 100%;
      flex-wrap: wrap;
      & > .single-blog-type {
        font-size: 16px;
        width: max-content;
      }
    }
  }
`;
