import * as React from "react";
import { styled } from "@mui/system";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const FooterContainer = styled((props: WithThemeProps & any) => (
  <footer {...props} />
))`
  &.footer-container {
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 100vw;
    height: fit-content;
    background-color: ${({ theme }) => theme.palette.primary.main};
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      height: auto;
    }
    & > .footer {
      padding: ${({ theme }) => theme.spacing(8, 10)};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 1440px;

      & .link-groups-container {
        display: flex;
        align-items: flex-start;
        width: 50%;

        & > * {
          flex: 1;
        }
      }
      & > .footer-top {
        min-height: 200px;
      }

      & .section-company {
        color: ${({ theme }) => theme.palette.secondary.main};
        display: flex;
        flex-direction: column;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};

        & > h2 {
          font-size: 40px;
          font-weight: 700;
          line-height: 100%;
          margin-bottom: ${({ theme }) => theme.spacing(5)};
        }
        & > .logos-and-follow {
          display: flex;
          flex-direction: column;
          gap: ${({ theme }) => theme.spacing(1.5)};
          & > p {
            font-size: 14px;
          }
          & > .social-logos {
            display: flex;
            gap: ${({ theme }) => theme.spacing(1)};
          }
        }

        & > p {
          line-height: 100%;
          margin-top: 36px;
          margin-bottom: 16px;
          font-size: 15px;
        }
      }

      & > .footer-top,
      .footer-bottom {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      & > p {
        font-size: 12px;
        color: ${({ theme: { palette } }: ThemeType) => palette.secondary.main};
        opacity: 0.8;
        & > a {
          text-decoration: underline;
          color: ${({ theme: { palette } }: ThemeType) =>
            palette.secondary.main};
        }
      }
      & > .footer-bottom {
        min-height: 50px;
        & > .link-groups-container {
          & > a {
            font-size: 12px;
            color: ${({ theme: { palette } }: ThemeType) =>
              palette.secondary.main};
            opacity: 0.8;
          }
        }
      }
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        height: auto;
        padding: ${({ theme }) => theme.spacing(5, 2, 2, 2)};
        position: relative;

        & .section-company {
          display: block;
        }
        & > p {
          font-size: 10px;
        }
        & > .footer-top {
          flex-direction: column;
          width: 100%;
          gap: ${({ theme }) => theme.spacing(4)};
          & > .section-company {
            width: 100%;
          }
          & > .link-groups-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: ${({ theme }) => theme.spacing(5)};
            width: 100%;
            margin-bottom: ${({ theme }) => theme.spacing(4)};
            & > .logos-and-follow {
              display: flex;
              flex-direction: column;
              gap: ${({ theme }) => theme.spacing(1.5)};
              color: ${({ theme }) => theme.palette.secondary.main};

              & > p {
                font-size: 13px;
                font-weight: 700;
              }
              & > .social-logos {
                display: flex;
                gap: ${({ theme }) => theme.spacing(1)};
              }
            }
            a,
            a:first-of-type {
              font-size: 13px;
            }
          }
        }
        & > .footer-bottom {
          flex-direction: column-reverse;
          gap: ${({ theme }) => theme.spacing(2)};

          & > .link-groups-container {
            width: 100%;
            gap: ${({ theme }) => theme.spacing(3)};
            & > a,
            a:first-of-type {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
`;
