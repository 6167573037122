import React from "react";
import { styled } from "@mui/system";
import { WithThemeProps } from "utils/general";
import Modal, { ModalProps } from "@mui/material/Modal";
import { ThemeType } from "utils/theme";

export const NewsletterModalContainer = styled(
  (props: WithThemeProps & ModalProps) => <Modal {...props} />
)`
  &.newsletter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    & > .modal-content {
      position: relative;
      max-width: 832px;
      max-height: 377px;
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(6, 10)};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
      border-radius: 4px;
      box-shadow: 0px 4px 40px rgba(170, 170, 170, 0.25);
      background-color: ${({ theme: { palette } }: ThemeType) =>
        palette.primaryLight};
      & > h3,
      & > p {
        font-family: "utopia-std-display";
        text-align: center;
      }
      & > h3 {
        font-style: italic;
        font-size: 60px;
      }
      & > p {
        color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
        line-height: 125.5%;
        max-width: 400px;
      }
      & > .social-links {
        display: flex;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(1.5)};

        & > svg,
        & > a svg {
          fill: #463f39;
          width: 20px;
          height: 20px;
        }
      }
      & > .newsletter-container {
        & > .signup-button {
          font-size: 14px;
        }
      }
      & > .modal-close {
        position: absolute;
        right: 10px;
        top: 10px;
        & > * {
          height: 20px;
          width: 20px;
          color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
        }
      }
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        max-width: 80vw;
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(7, 2)};
        max-height: none;

        & > .newsletter-container {
          flex-direction: column;
          width: 100%;
          margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(4)};

          & > .signup-button {
            width: 100%;
            min-height: 52px;
          }
          & > .email-input {
            width: 100%;
          }
        }
        & > h3 {
          font-size: 48px;
          max-width: 253px;
        }
        & > p {
          font-size: 14px;
          max-width: none;
        }
      }
    }
  }
`;
