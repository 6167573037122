import React from "react";
import { graphql } from "gatsby";
import Seo from "components/common/seo";
import BlogContent from "./components/blog-content";
import BlogTop from "./components/top";
import { BlogContainer } from "./styles";
import { BlogTemplateProps } from "./types";

const BlogTemplate: React.FC<BlogTemplateProps> = ({ data: { blog } }) => {
  const document = blog.data;

  return (
    <>
      <Seo
        openGraph={{
          type: "article",
        }}
      />

      <BlogContainer className="blog-container">
        <BlogTop image={document.blog_post_cover.gatsbyImageData} />
        <BlogContent
          author={document.author.document.data}
          date={document.release_date}
          title={document.title.richText}
          image={document.blog_post_inner_image.gatsbyImageData}
          imageCaption={document.image_caption.richText}
          firstParagraph={document.first_paragraph.richText}
          content={document.body}
          uid={blog.uid}
        />
      </BlogContainer>
    </>
  );
};

export const query = graphql`
  query BlogPost($uid: String!) {
    blog: prismicBlog(uid: { eq: $uid }) {
      data {
        release_date(formatString: "MMM DD, yyyy")
        title {
          richText
        }
        first_paragraph {
          richText
        }
        blog_post_image {
          gatsbyImageData
          alt
        }
        blog_post_inner_image {
          gatsbyImageData
          alt
        }
        blog_post_cover {
          gatsbyImageData
          alt
          url
        }
        body {
          ... on PrismicBlogDataBodyBlogParagraph {
            primary {
              section_title {
                richText
              }
              section_paragraph {
                richText
              }
              is_final_paragraph
            }
          }
        }
        author {
          document {
            ... on PrismicAuthor {
              data {
                author_name {
                  richText
                }
                author_image {
                  gatsbyImageData
                }
              }
            }
          }
        }
        image_caption {
          richText
        }
      }
      uid
    }
  }
`;

export default BlogTemplate;
