import React, { useEffect, useState } from "react";
import { GetCoveredButtonProps } from "./types";
import { EventType } from "components/pages/home/types";
import mParticle from "@mparticle/web-sdk";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import { UTMProps } from "types/Utms";
import { CustomLink } from "./styles";

const GetCoveredButton: React.FC<GetCoveredButtonProps> = ({
  className,
  text,
  location,
}) => {
  const [url, setUrl] = useState(process.env.GATSBY_ONBOARDING_URL);
  const currentLocation = useLocation();

  const logOnClickEvent = () => {
    mParticle.logEvent("clicked_get_covered", EventType.Other, {
      cta_location: location,
    });
  };

  useEffect(() => {
    const customerId = window.localStorage.getItem("customer-id");
    const UTMQuery = queryString.parseUrl(currentLocation.href);

    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    }: UTMProps = UTMQuery.query;

    const queryParams = queryString.stringify({
      customer_id: customerId || null,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    }, {
      skipNull: true,
    });

    if (queryParams) {
      setUrl(`${process.env.GATSBY_ONBOARDING_URL}?${queryParams}`);
    }
  }, [currentLocation.href]);

  return (
    <CustomLink
      className={`outbound-link ${className}`}
      onClick={logOnClickEvent}
      href={url}
    >
      {text}
    </CustomLink>
  );
};

export default GetCoveredButton;
