import React, { useCallback, useState } from "react";
import Link from "components/common/link";
import { LinksGroupContainer } from "../styles";
import { LinksGroupProps } from "../types";
import { Button } from "@mui/material";
import Newsletter from "../newsletter";

const LinksGroup: React.FC<LinksGroupProps> = ({ first, list }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  return (
    <LinksGroupContainer className="links-group-container">
      <p>{first.title}</p>
      {list?.map((link) =>
        link?.path === "newsletter" ? (
          <Button
            key={link.title}
            onClick={() => setIsOpen((open) => !open)}
            className="newsletter-signup"
          >
            {link.title}
          </Button>
        ) : link ? (
          <Link key={link.title} to={link.path}>
            {link.title}
          </Link>
        ) : (
          link
        )
      )}
      <Newsletter closeModal={handleClose} isOpen={isOpen} />
    </LinksGroupContainer>
  );
};

export default React.memo(LinksGroup);
