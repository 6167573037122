import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import * as React from "react";
import { Helmet } from "react-helmet";
import type { SeoDocument, SeoProps } from "./types";

const TRIM_SLASHES_REGEXP = /^\/+|\/+$/g

const Seo: React.FC<SeoProps> = ({
  title,
  description,
  openGraph = {},
  twitter = {}
}) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      prismicSeo {
        data {
          body {
            ... on PrismicSeoDataBodyPage {
              primary {
                title
                img {
                  url
                }
                summary
                preview_text
                path
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          title
          description
          locale
          url: siteUrl
        }
      }
    }
  `);

  const seoData = data.prismicSeo.data.body;
  const siteData = data.site.siteMetadata;

  const { pathname } = useLocation();
  const trimmedPathname = pathname.replace(TRIM_SLASHES_REGEXP, '');
  const url = `${siteData.url}${pathname}`;

  const { primary: seo = {} }: SeoDocument = (
    seoData.find(({ primary: { path = '' } }: SeoDocument) => (
      path.replace(TRIM_SLASHES_REGEXP, '') === trimmedPathname
    )) ||
    seoData.find(({ primary: { path = '' } }: SeoDocument) => path === '/')
  );

  const ogLocale = siteData.locale.replace('-',  '_');

  return (
    <Helmet htmlAttributes={{ lang: siteData.locale }}>
      <title>{title || seo.title}</title>

      <link rel='canonical' href={url}/>

      <meta name='description' content={description || seo.summary}/>

      <meta property="og:type" content={openGraph?.type || 'website'} />
      <meta property="og:url" content={url} />
      <meta property="og:locale" content={ogLocale} />
      <meta property="og:site_name" content={siteData.title} />
      <meta property="og:title" content={title || seo.title} />

      <meta
        property="og:description"
        content={description || seo.preview_text}
      />

      {(openGraph.imageUrl || seo.img?.url) && (
        <meta
          property="og:image"
          content={openGraph.imageUrl || seo.img?.url}
        />
      )}

      <meta
        name="twitter:card"
        content={twitter?.card || "summary_large_image"}
      />
    </Helmet>
  );
};

export default Seo;
