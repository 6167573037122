import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Box } from "@mui/material";
import BlogPreview from "../blog-preview";
import { MoreBlogsContainer } from "../styles";
import { MoreBlogsProps } from "../types";
import { PrismicRichText } from "@prismicio/react";
import { BlogType } from "components/pages/blog/components/types";

const MoreBlogs: React.FC<MoreBlogsProps> = ({ uid }) => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicBlog {
        nodes {
          data {
            author {
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    author_name {
                      richText
                    }
                  }
                }
              }
            }
            blog_post_image {
              gatsbyImageData
            }
            release_date(formatString: "MMM DD, yyyy")
            title {
              richText
            }
            type {
              slug
            }
          }
          uid
        }
      }
      title: allPrismicBlogPage {
        nodes {
          data {
            read_more_title {
              richText
            }
          }
        }
      }
    }
  `);
  const document: BlogType[] = data.pageData.nodes;
  const title = data.title.nodes[0].data.read_more_title.richText;
  return (
    <MoreBlogsContainer className="more-blogs-container">
      <PrismicRichText field={title} />
      <Box className="more-blogs-list">
        {document
          .filter((item) => item.uid !== uid)
          .sort(function (a, b) {
            return (
              new Date(b.data.release_date) - new Date(a.data.release_date)
            );
          })
          .slice(1, 4)
          .map((blog) => {
            console.log(blog);

            return (
              <BlogPreview
                title={blog.data.title.richText}
                author={blog.data.author}
                date={blog.data.release_date}
                previewImage={blog.data.blog_post_image.gatsbyImageData}
                url={`/blog/${blog.data.type.slug}/${blog.uid}`}
                key={blog.uid}
              />
            );
          })}
      </Box>
    </MoreBlogsContainer>
  );
};

export default MoreBlogs;
