import * as React from "react";
import { styled } from "@mui/system";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export const CustomLink = styled((props: WithThemeProps & any) => (
  <OutboundLink {...props} />
))`
  &.outbound-link {
    border-radius: 4px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.primary.main};
    color: ${({ theme: { palette } }: ThemeType) => palette.p_white.full};
    font-size: 14px;
  }
`;
