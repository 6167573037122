import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const BlogTopContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="section" {...props} />
))`
  &.blog-top-container {
    min-width: 100vw;
    min-height: 100vh;
    & > .blog-top-background-image {
      width: 100%;
      height: 100vh;
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding-top: 76px;
      & > .blog-top-background-image {
        height: 50vh;
      }
    }
  }
`;

export const BlogContentContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="section" {...props} />
)`
  &.blog-content-container {
    max-width: 1440px;
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 10)};
    color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 2)};
    }
    & > .back-to-blogs-link {
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        display: none;
      }
      & > .back-to-blogs-container {
        position: relative;
        width: 10%;
        height: 50px;
        overflow: hidden;

        & > svg {
          transform: rotate(180deg);
          position: relative;
          top: 25px;
          fill: ${({ theme: { palette } }: ThemeType) => palette.primary.main};
        }
        & > .back-to-blogs-text {
          position: absolute;
          display: flex;
          align-items: center;
          top: 20px;
          right: 0;
          font-size: 14px;
          background-color: ${({ theme: { palette } }: ThemeType) =>
            palette.p_white.full};
          padding: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
        }
      }
    }
    & > .blog-content-inner {
      max-width: 70%;
      margin: 0 auto;
      background-color: ${({ theme: { palette } }: ThemeType) =>
        palette.p_white.full};
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(11, 13.5)};
      transform: translateY(-40vh);
      box-shadow: 0px 4px 40px rgba(71, 38, 209, 0.12);
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        max-width: none;
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(4, 2)};
        transform: translateY(-50vh);
      }
      & > .main-content-container {
        display: flex;
        flex-direction: column;
        & > p {
          font-size: 13px;
          font-weight: 400;
          line-height: 22px;
        }

        gap: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
        & > .image-container {
          display: flex;
          align-items: flex-end;
          position: relative;
          padding: ${({ theme: { spacing } }: ThemeType) => spacing(3, 0)};

          & > .page-inner-image {
            min-width: 100%;
            transform: translateX(-195px);
          }
          & > .caption-container {
            position: absolute;
            right: 0;
            display: flex;
            flex-direction: column;
            gap: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};

            & > p {
              font-size: 13px;
              width: max-content;
              line-height: 1;
              font-style: italic;
              color: ${({ theme: { palette } }: ThemeType) =>
                palette.p_grey.text};
            }
          }
          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            flex-direction: column;
            align-items: flex-start;
            gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};

            & > .page-inner-image {
              transform: none;
            }
            & > .caption-container {
              position: relative;
            }
          }
        }
        & > .content-paragraph {
          & > h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
          }
          & > p {
            font-size: 13px;
            font-weight: 400;
            line-height: 22px;
          }
          & > ul {
            & > li {
              font-size: 13px;
              font-weight: 400;
              line-height: 22px;
            }
          }
        }
      }
      & > .blog-header-container {
        display: flex;
        flex-direction: column;
        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          flex-direction: column-reverse;
          padding-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(5)};
          gap: ${({ theme: { spacing } }: ThemeType) => spacing(8)};
        }
        & > .blog-title-container {
          display: flex;
          justify-content: center;
          text-align: center;
          & > h1 {
            font-size: 32px;
            font-weight: normal;
            width: 60%;
            font-family: "utopia-std-display";
          }
          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            & > h1 {
              width: 90%;
              font-size: 24px;
            }
          }
        }
        & > .author-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "utopia-std-display";
          color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};
          padding: ${({ theme: { spacing } }: ThemeType) =>
            spacing(10, 0, 5, 0)};
          & > .social-links {
            display: flex;
            gap: ${({ theme: { spacing } }: ThemeType) => spacing(1.5)};
            & > svg {
              fill: ${({ theme: { palette } }: ThemeType) =>
                palette.p_black.text};
            }
            & > a {
              & > svg {
                fill: ${({ theme: { palette } }: ThemeType) =>
                  palette.p_black.text};
              }
            }
          }
          & > .author-and-date {
            display: flex;
            align-items: center;
            font-size: 14px;
            gap: ${({ theme: { spacing } }: ThemeType) => spacing(1.5)};
            & > .author-name {
              font-style: italic;
              font-weight: bold;
            }
          }
          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            flex-direction: column;
            justify-content: center;
            padding: ${({ theme: { spacing } }: ThemeType) => spacing(0)};
            & > .social-links {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export const AvatarStyled = styled((props: WithThemeProps & AvatarProps) => (
  <Avatar {...props} />
))``;

export const BlogPreviewContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="div" {...props} />
)`
  &.blog-preview-container {
    display: flex;
    height: 100px;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(5)};
    font-family: "utopia-std-display";
    & > .blog-preview-image {
      flex-basis: 30%;
    }
    & > .preview-content-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: 70%;
      & > h1 {
        font-size: 18px;
        line-height: 130%;
      }
      & > .author-and-date-container {
        display: flex;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
        font-size: 14px;
        color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
        & > p {
          font-style: italic;
          font-weight: bold;
        }
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
      height: 60px;
      & > .blog-preview-image {
        flex-basis: 50%;
      }
      & > .preview-content-container {
        & > h1 {
          font-size: 14px;
        }
        & > .author-and-date-container {
          font-size: 12px;
        }
      }
    }
  }
`;

export const MoreBlogsContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="section" {...props} />
))`
  &.more-blogs-container {
    & > h4 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
      color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};
    }
    & > .more-blogs-list {
      display: flex;
      flex-direction: column;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
    }
    padding-top: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding-top: ${({ theme: { spacing } }: ThemeType) => spacing(5)};
    }
  }
`;
