import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { BlogTopContainer } from "../styles";
import { BlogTopProps } from "../types";

const BlogTop: React.FC<BlogTopProps> = ({ image }) => {
  return (
    <BlogTopContainer className="blog-top-container">
      <GatsbyImage
        className="blog-top-background-image"
        image={image}
        alt="blog image"
      />
    </BlogTopContainer>
  );
};

export default BlogTop;
