import mParticle from "@mparticle/web-sdk";

export const onRouteUpdate = ({ location }) => {
  return mParticle.logPageView(location.pathname);
}

export const onInitialClientRender = () => {
  window.scrollTo(0, 0);
};

export { wrapPageElement, wrapRootElement } from "utils/app";
