import { useEffect, useState, useCallback } from "react";

const useScrollPosition = () => {
  const [offset, setOffset] = useState(0);
  const [scrollUp, setScrollUp] = useState(false);
  const [screenHeightScrolled, setScreenHeightScrolled] = useState(false);
  const [y, setY] = useState(0);
  const handleNavigation = useCallback(
    (e) => {
      if (typeof window !== `undefined`) {
        const window = e.currentTarget;
        if (y > window.scrollY) {
          setScrollUp(true);
        } else if (y < window.scrollY) {
          setScrollUp(false);
        }
        setY(window.scrollY);
      }
    },
    [y]
  );

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setY(window.scrollY);
      window.addEventListener("scroll", handleNavigation);

      return () => {
        window.removeEventListener("scroll", handleNavigation);
      };
    }
  }, [handleNavigation]);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.scrollY);
      };
    }
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined` && offset >= 60) {
      setScreenHeightScrolled(true);
    } else {
      setScreenHeightScrolled(false);
    }
  }, [offset]);

  return { offset, scrollUp, screenHeightScrolled };
};

export default useScrollPosition;
