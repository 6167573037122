import { red } from "@mui/material/colors";
import { createTheme, PaletteOptions, Theme } from "@mui/material/styles";

const primary = "#4726D1";
const secondary = "#E2FBFF";

const PaletteExtension: PaletteOptions = {
  disabled: {
    main: "#C4C4C4",
    contrastText: "#888888",
  },
  department: {
    business: {
      background: "#D6E9CC",
      text: "#2B8D63",
    },
    rnd: {
      background: "#D3C9FF",
      text: "#6E4598",
    },
    marketing: {
      background: "#C9BCFF",
      text: "#4726D1",
    },
    finance: {
      background: "#FEFFD6",
      text: "#9F9322",
    },
    engineering: {
      background: "#CEFFFC",
      text: "#2F928C",
    },
    growth: {
      background: "#FFDBF7",
      text: "#A03274",
    },
    data: {
      background: "#FFE9CF",
      text: "#B9662A",
    },
  },
  p_grey: {
    background: "#B1ACBF",
    backgroundSecondary: "#e9e9e9",
    border: "#BFBFBF",
    borderSecondary: "#DDDBE3",
    text: "#878787",
    textDark: "#4F4F4F",
    bubbleMessage: "#E5E5E5",
    light: "#DADADA",
  },
  primaryLight: secondary,
  p_white: {
    full: "#FFFFFF",
    light: "#F8F7FA",
  },
  p_pink: {
    background: "#F6EDFF",
  },
  p_black: {
    text: "#27303B",
  },
  p_yellow: {
    background: "#F7D154",
    secondary: "#FEFBF2",
    light: "#E2D4BC",
    addon: "#FBE8A9",
  },
  shadow: {
    main: "#bf7dff",
  },
};

// A custom theme for this app
const FayeTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 720,
      tablet: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    text: {
      primary,
    },
    background: {
      default: "#F5F5F5",
    },
    primary: {
      main: primary,
      contrastText: "#FFFFFF",
      light: "#F6EDFF",
    },
    secondary: {
      main: secondary,
      light: "#CAF0F4",
    },
    error: {
      main: red.A400,
    },
    ...PaletteExtension,
  },
  typography: {
    fontFamily: [
      "TWK Lausanne",
      "utopia-std-display",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export type ThemeType = { theme: Theme };

export default FayeTheme;
