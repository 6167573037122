import { useEffect, useState } from "react";
import { EventType } from "components/pages/home/types";
import mParticle from "@mparticle/web-sdk";

const useNewsletter = (location: string) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const register = () => {
    const verifyEmail =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
        email
      );
    if (!verifyEmail) {
      return setError(true);
    }
    mParticle.logEvent("lead_added", EventType.Other, {
      email: email,
      location,
    });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDone(true);
    }, 2000);
  };
  useEffect(() => {
    if (done) {
      setTimeout(() => {
        setDone(false);
        setEmail("");
      }, 2000);
    }
  }, [done]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  }, [error]);

  return { register, error, setError, email, setEmail, loading, done };
};

export default useNewsletter;
