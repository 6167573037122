import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/system";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const LogoContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="div" {...props} />
))`
  &.logo-container {
    position: relative;
    & > .version-alert-trigger-tooltip {
      &:hover {
        cursor: auto;
      }
    }
    & > .version-alert-trigger {
      width: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
      height: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
      position: absolute;
      left: 0;
      &:hover {
        cursor: auto;
      }
    }
  }
`;
