import React from "react";
import { Box, BoxProps, styled } from "@mui/system";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Typography } from "@mui/material";
import { AppLinksContainer } from "../styles";
import { AppLinksProps } from "../types";

const AppLinks: React.FC<AppLinksProps> = ({
  googlePlaySVG,
  appleStoreSVG,
}) => {
  return (
    <AppLinksContainer className={"app-links-container"}>
      <Typography variant={"h4"}>Download the app</Typography>
      <Box className={"app-links"}>
        <Button
          rel="noopener"
          href={"https://assets.withfaye.com/ios"}
          target={"_blank"}
        >
          <GatsbyImage alt={"link to app store"} image={appleStoreSVG} />
        </Button>
        <Button
          rel="noopener"
          href={"https://assets.withfaye.com/android"}
          target={"_blank"}
        >
          <GatsbyImage alt={"link to google play"} image={googlePlaySVG} />
        </Button>
      </Box>
    </AppLinksContainer>
  );
};

export default AppLinks;
