import * as React from "react";
import { OutlinedInput, InputAdornment } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { NewsletterContainer } from "../styles";
import { NewsletterProps } from "../types";
import useNewsletter from "../hooks/useNewsletter";
import LoaderButton from "../common/loader-button";

const Newsletter: React.FC<NewsletterProps> = ({ location }) => {
  const { register, error, setError, email, setEmail, loading, done } =
    useNewsletter(location);
  return (
    <NewsletterContainer className="newsletter-container">
      <OutlinedInput
        startAdornment={
          <InputAdornment position="start">
            <MailOutlineIcon style={{ color: "#4726D1" }} />
          </InputAdornment>
        }
        value={email}
        error={error}
        onFocus={() => setError(false)}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email address"
        className="email-input"
      />
      <LoaderButton loading={loading} onClick={register} done={done} />
    </NewsletterContainer>
  );
};

export default Newsletter;
