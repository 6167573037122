import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Box } from "@mui/material";
import Link from "components/common/link";
import { BlogPreviewContainer } from "../styles";
import { BlogPreviewProps } from "../types";
import { PrismicRichText } from "@prismicio/react";

const BlogPreview: React.FC<BlogPreviewProps> = ({
  title,
  author,
  date,
  previewImage,
  url,
}) => {
  return (
    <Link to={url}>
      <BlogPreviewContainer className="blog-preview-container">
        <GatsbyImage
          className="blog-preview-image"
          image={previewImage}
          alt="blog preview image"
        />
        <Box className="preview-content-container">
          <PrismicRichText field={title} />
          <Box className="author-and-date-container">
            <PrismicRichText
              field={author.document.data.author_name.richText}
            />
            <Box className="date-container">{date}</Box>
          </Box>
        </Box>
      </BlogPreviewContainer>
    </Link>
  );
};

export default BlogPreview;
